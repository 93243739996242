<template>
  <crud-form
    :schema="schema"
    :model="form"
    :path="'payment-method'"
    :form-title="$t('addPaymentMethod')"
    :redirect-route="'PaymentMethodList'"
  ></crud-form>
</template>

<script>
  import CrudForm from "../../components/CrudForm";
  import { schema } from './form/payment-method'
  import { form } from './forms/payment-method'

  export default {
    name: 'AddMethodList',
    components: {
      CrudForm
    },
    data: () => {
      return {
        form,
        schema,
      }
    },
  }
</script>
